import { createSlice } from '@reduxjs/toolkit';
import { ContractStatus } from '../core/enums/contract-status';
import { OrderBy } from '../core/enums/order-sort-by';

export const internalSlice = createSlice({
  name: 'internal',
  initialState: {
    showConfirmationModal: { visibility: false, text: { title: '', body: '', id: '', buttonReturnText: '', buttonConfirmationText: '', closeButton: true, replaceText: '' }, functionConfirmation: () => { }, functionCancellation: () => { }, functionConfirmation2: () => { } },
    showRegisterCustomersModal: false,
    showRegisterAgentsModal: false,
    showCreateTicketsModal: false,
    showEditTicketsModal: false,
    showAlertFeedback: { visibility: false, message: '', signalIcon: false, timeOut: 4000 },
    showEditAgentsModal: false,
    showCreateAgentsModal: false,
    showEditConsumersModal: false,
    showCreateConsumersModal: false,
    showEditDepartmentModal: false,
    showCreateDepartmentModal: false,
    showEditEmailModal: false,
    showEditPhoneModal: false,
    showCreateEmailModal: false,
    showEditMessageTriggerModal: false,
    showCreateMessageTriggerModal: false,
    showEditTriggerTypeModal: false,
    showCreateTriggerTypeModal: false,
    showCreateIntegrationModal: false,
    showRegisterIntegrationModal: false,
    showCreateParamModal: false,
    showEditIntegrationModal: false,
    showErrorFeedback: false,
    showRegisterTagsModal: false,
    showEditTagsModal: false,
    showMeetButton: false,
    showCallNotificationBar: false,
    showRequestWhatsappModal: false,
    showRequestTelefoneModal: false,
    showModalConsumersSimilarData: false,
    showModalUnifyConsumers: false,
    blockLoadTicketBackup: true,
    showEditTaskModal: false,
    cleanMessageEditor: false,
    updateButtonNumberOfTickets: false,
    showAcquirePlatformComponent: { currentContractStatusId: ContractStatus.Trial, visibility: false, limitDays: 0, quantityAgents: 2 },
    sortByList: { sort_by: OrderBy.desc, listName: null },
    orderByList: OrderBy.desc,
    openSidebar: true,
    sidebarPage: null,
    forceLogout: false,
    forceLogoutExpired: false,
    mute: false,
    updatedBot: false,
    openDashboard: true,
    promptUpdatedNlp: true,
    userWebchatId: '' as string,
    openHistory: true,
    showSendTicketCopyModal: false,
    inAttendance: false,
    startAttendance: false,
    showEditTriageModal: false,
    showCreateTriageModal: false,
    showRegisterDomainModal: false,
    showViewDomainModal: false,
    showCreateTemplateMessage: false,
    showEditTemplateMessage: false,
    showTemplateTagModal: false,
    showEditTypeAgent: false,
    showEditPaswordAgent: false,
    showRecoverPasswordAgente: false,
    showExportTicketModal: false,
    showEditFacebookPage: false,
    showTicketNotification: false,
    soundNotification: '' as string,
    visualNotification: '' as string,
    showLinkModal: false,
    showManageTicketsModal: false,
    showExportAllTicketModal: false,
    showMessageSend: false,
    nextTicketId: '' as string,
    quickMessageId: '' as string,
    reopenTicket: '' as string,
    showRegisterSummary: false,
    historySortBy: '' as string,
  },
  reducers: {
    setShowConfirmationModal: (state, action) => {
      state.showConfirmationModal = action.payload;
    },
    setRegisterCustomersModal: (state, action) => {
      state.showRegisterCustomersModal = action.payload;
    },
    setRegisterAgentsModal: (state, action) => {
      state.showRegisterAgentsModal = action.payload;
    },
    setShowEditTicketsModal: (state, action) => {
      state.showEditTicketsModal = action.payload;
    },
    setShowAlertFeedback: (state, action) => {
      state.showAlertFeedback = action.payload;
    },
    setShowEditAgentsModal: (state, action) => {
      state.showEditAgentsModal = action.payload;
    },
    setShowCreateAgentsModal: (state, action) => {
      state.showCreateAgentsModal = action.payload;
    },
    setShowEditConsumersModal: (state, action) => {
      state.showEditConsumersModal = action.payload;
    },
    setShowCreateConsumersModal: (state, action) => {
      state.showCreateConsumersModal = action.payload;
    },
    setShowEditDepartmentModal: (state, action) => {
      state.showEditDepartmentModal = action.payload;
    },
    setShowCreateDepartmentModal: (state, action) => {
      state.showCreateDepartmentModal = action.payload;
    },
    setShowEditEmailModal: (state, action) => {
      state.showEditEmailModal = action.payload;
    },
    setShowEditPhoneModal: (state, action) => {
      state.showEditPhoneModal = action.payload;
    },
    setShowCreateEmailModal: (state, action) => {
      state.showCreateEmailModal = action.payload;
    },
    setShowEditMessageTriggerModal: (state, action) => {
      state.showEditMessageTriggerModal = action.payload;
    },
    setShowCreateMessageTriggerModal: (state, action) => {
      state.showCreateMessageTriggerModal = action.payload;
    },
    setShowEditTriggerTypeModal: (state, action) => {
      state.showEditTriggerTypeModal = action.payload;
    },
    setShowCreateTriggerTypeModal: (state, action) => {
      state.showCreateTriggerTypeModal = action.payload;
    },
    setShowCreateIntegrationModal: (state, action) => {
      state.showCreateIntegrationModal = action.payload
    },
    setShowRegisterIntegrationModal: (state, action) => {
      state.showRegisterIntegrationModal = action.payload
    },
    setShowEditIntegrationModal: (state, action) => {
      state.showEditIntegrationModal = action.payload
    },
    setShowCreateParamModal: (state, action) => {
      state.showCreateParamModal = action.payload
    },
    setShowErrorFeedback: (state, action) => {
      state.showErrorFeedback = action.payload;
    },
    setShowRegisterTagsModal: (state, action) => {
      state.showRegisterTagsModal = action.payload;
    },
    setShowEditTagsModal: (state, action) => {
      state.showEditTagsModal = action.payload;
    },
    setShowMeetButton: (state, action) => {
      state.showMeetButton = action.payload;
    },
    setShowCallNotificationBar: (state, action) => {
      state.showCallNotificationBar = action.payload;
    },
    setShowRequestWhatsappModal: (state, action) => {
      state.showRequestWhatsappModal = action.payload;
    },
    setShowRequestTelefoneModal: (state, action) => {
      state.showRequestTelefoneModal = action.payload;
    },
    setShowEditTaskModal: (state, action) => {
      state.showEditTaskModal = action.payload;
    },
    setCleanMessageEditor: (state, action) => {
      state.cleanMessageEditor = action.payload;
    },
    setUpdateButtonNumberOfTickets: (state, action) => {
      state.updateButtonNumberOfTickets = action.payload;
    },
    setShowAcquirePlatformComponent: (state, action) => {
      state.showAcquirePlatformComponent = action.payload;
    },
    setSortByList: (state, action) => {
      state.sortByList = action.payload;
    },
    setOrderByList: (state, action) => {
      state.orderByList = action.payload;
    },
    setOpenSidebar: (state, action) => {
      state.openSidebar = action.payload;
    },
    setSidebarPage: (state, action) => {
      state.sidebarPage = action.payload;
    },
    setForceLogout: (state, action) => {
      state.forceLogout = action.payload;
    },
    setForceLogoutExpired: (state, action) => {
      state.forceLogoutExpired = action.payload;
    },
    setMute: (state, action) => {
      state.mute = action.payload;
    },
    setShowUpdatedBot: (state, action) => {
      state.updatedBot = action.payload;
    },
    setOpenDashboard: (state, action) => {
      state.openDashboard = action.payload;
    },
    setPromptUpdatedNlp: (state, action) => {
      state.promptUpdatedNlp = action.payload;
    },
    setUserWebchatId: (state, action) => {
      state.userWebchatId = action.payload;
    },
    setShowModalConsumersSimilarData: (state, action) => {
      state.showModalConsumersSimilarData = action.payload;
    },
    setShowModalUnifyConsumers: (state, action) => {
      state.showModalUnifyConsumers = action.payload;
    },
    setBlockLoadTicketBackup: (state, action) => {
      state.blockLoadTicketBackup = action.payload;
    },
    setOpenHistory: (state, action) => {
      state.openHistory = action.payload;
    },
    setShowSendTicketCopyModal: (state, action) => {
      state.showSendTicketCopyModal = action.payload;
    },
    setInAttendance: (state, action) => {
      state.inAttendance = action.payload;
    },
    setStartAttendance: (state, action) => {
      state.startAttendance = action.payload;
    },
    setShowEditTriageModal: (state, action) => {
      state.showEditTriageModal = action.payload;
    },
    setShowCreateTriageModal: (state, action) => {
      state.showCreateTriageModal = action.payload;
    },
    setShowRegisterDomainModal: (state, action) => {
      state.showRegisterDomainModal = action.payload;
    },
    setShowViewDomainModal: (state, action) => {
      state.showViewDomainModal = action.payload;
    },
    setShowCreateTemplateMessage: (state, action) => {
      state.showCreateTemplateMessage = action.payload
    },
    setShowEditTemplateMessage: (state, action) => {
      state.showEditTemplateMessage = action.payload
    },
    setShowTemplateTagModal: (state, action) => {
      state.showTemplateTagModal = action.payload;
    },
    setShowEditTypeAgent: (state, action) => {
      state.showEditTypeAgent = action.payload;
    },
    setShowEditPaswordAgent: (state, action) => {
      state.showEditPaswordAgent = action.payload;
    },
    setShowRecoverPasswordAgente: (state, action) => {
      state.showRecoverPasswordAgente = action.payload;
    },
    setShowTicketNotification: (state, action) => {
      state.showTicketNotification = action.payload;
    },
    setSoundNotification: (state, action) => {
      state.soundNotification = action.payload;
    },
    setVisualNotification: (state, action) => {
      state.visualNotification = action.payload;
    },
    setShowManageTicketsModal: (state, action) => {
      state.showManageTicketsModal = action.payload;
    },
    setShowExportTicketModal: (state, action) => {
      state.showExportTicketModal = action.payload;
    },
    setShowRegisterSummary: (state, action) => {
      state.showRegisterSummary = action.payload;
    },
    setShowEditFacebookPage: (state, action) => {
      state.showEditFacebookPage = action.payload;
    },
    setShowLinkModal: (state, action) => {
      state.showLinkModal = action.payload;
    },
    setShowExportAllTicketModal: (state, action) => {
      state.showExportAllTicketModal = action.payload;
    },
    setShowMessageSend: (state, action) => {
      state.showMessageSend = action.payload;
    },
    setNextTicketId: (state, action) => {
      state.nextTicketId = action.payload;
    },
    setQuickMessageId: (state, action) => {
      state.quickMessageId = action.payload
    },
    setReopenTicket: (state, action) => {
      state.reopenTicket = action.payload;
    },
    setHistorySortBy: (state, action) => {
      state.historySortBy = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setShowConfirmationModal,
  setRegisterCustomersModal,
  setRegisterAgentsModal,
  setShowEditTicketsModal,
  setShowAlertFeedback,
  setShowEditAgentsModal,
  setShowCreateAgentsModal,
  setShowEditConsumersModal,
  setShowCreateConsumersModal,
  setShowErrorFeedback,
  setShowRegisterTagsModal,
  setShowEditTagsModal,
  setShowMeetButton,
  setShowCallNotificationBar,
  setShowRequestWhatsappModal,
  setShowRequestTelefoneModal,
  setCleanMessageEditor,
  setUpdateButtonNumberOfTickets,
  setShowAcquirePlatformComponent,
  setShowCreateDepartmentModal,
  setShowEditDepartmentModal,
  setShowEditEmailModal,
  setShowEditPhoneModal,
  setShowCreateEmailModal,
  setShowEditMessageTriggerModal,
  setShowCreateMessageTriggerModal,
  setShowEditTriggerTypeModal,
  setShowCreateTriggerTypeModal,
  setSortByList,
  setOrderByList,
  setOpenSidebar,
  setSidebarPage,
  setForceLogout,
  setForceLogoutExpired,
  setMute,
  setShowUpdatedBot,
  setOpenDashboard,
  setPromptUpdatedNlp,
  setShowEditTaskModal,
  setShowModalConsumersSimilarData,
  setShowModalUnifyConsumers,
  setBlockLoadTicketBackup,
  setOpenHistory,
  setShowSendTicketCopyModal,
  setShowCreateIntegrationModal,
  setShowEditIntegrationModal,
  setShowCreateParamModal,
  setUserWebchatId,
  setInAttendance,
  setStartAttendance,
  setShowEditTriageModal,
  setShowCreateTriageModal,
  setShowRegisterDomainModal,
  setShowViewDomainModal,
  setShowCreateTemplateMessage,
  setShowEditTemplateMessage,
  setShowTemplateTagModal,
  setShowEditTypeAgent,
  setShowEditPaswordAgent,
  setShowRecoverPasswordAgente,
  setShowTicketNotification,
  setSoundNotification,
  setVisualNotification,
  setShowManageTicketsModal,
  setShowExportTicketModal,
  setShowRegisterSummary,
  setShowEditFacebookPage,
  setShowLinkModal,
  setShowExportAllTicketModal,
  setShowMessageSend,
  setNextTicketId,
  setQuickMessageId,
  setReopenTicket,
  setHistorySortBy,
} = internalSlice.actions;

export const getShowConfirmationModal = state => (state.internal.showConfirmationModal !== undefined ? state.internal.showConfirmationModal : internalSlice.getInitialState().showConfirmationModal);
export const getShowRegisterCustomersModal = state => (state.internal.showRegisterCustomersModal !== undefined ? state.internal.showRegisterCustomersModal : internalSlice.getInitialState().showRegisterCustomersModal);
export const getShowRegisterAgentsModal = state => (state.internal.showRegisterAgentsModal !== undefined ? state.internal.showRegisterAgentsModal : internalSlice.getInitialState().showRegisterAgentsModal);
export const getShowEditTicketsModal = (state: { internal: { showEditTicketsModal: boolean } }) => (state.internal.showEditTicketsModal !== undefined ? state.internal.showEditTicketsModal : internalSlice.getInitialState().showEditTicketsModal);
export const getShowAlertFeedback = state => (state.internal.showAlertFeedback !== undefined ? state.internal.showAlertFeedback : internalSlice.getInitialState().showAlertFeedback);
export const getShowEditAgentsModal = state => (state.internal.showEditAgentsModal !== undefined ? state.internal.showEditAgentsModal : internalSlice.getInitialState().showEditAgentsModal);
export const getShowCreateAgentsModal = state => (state.internal.showCreateAgentsModal !== undefined ? state.internal.showCreateAgentsModal : internalSlice.getInitialState().showCreateAgentsModal);
export const getShowEditConsumersModal = state => (state.internal.showEditConsumersModal !== undefined ? state.internal.showEditConsumersModal : internalSlice.getInitialState().showEditConsumersModal);
export const getShowCreateConsumersModal = state => (state.internal.showCreateConsumersModal !== undefined ? state.internal.showCreateConsumersModal : internalSlice.getInitialState().showCreateConsumersModal);
export const getShowEditEmailModal = state => (state.internal.showEditEmailModal !== undefined ? state.internal.showEditEmailModal : internalSlice.getInitialState().showEditEmailModal);
export const getShowEditPhoneModal = state => (state.internal.showEditPhoneModal !== undefined ? state.internal.showEditPhoneModal : internalSlice.getInitialState().showEditPhoneModal);
export const getShowCreateEmailModal = state => (state.internal.showCreateEmailModal !== undefined ? state.internal.showCreateEmailModal : internalSlice.getInitialState().showCreateEmailModal);
export const getShowEditMessageTriggerModal = state => (state.internal.showEditMessageTriggerModal !== undefined ? state.internal.showEditMessageTriggerModal : internalSlice.getInitialState().showEditMessageTriggerModal);
export const getShowCreateMessageTriggerModal = state => (state.internal.showCreateMessageTriggerModal !== undefined ? state.internal.showCreateMessageTriggerModal : internalSlice.getInitialState().showCreateMessageTriggerModal);
export const getShowEditTriggerTypeModal = state => (state.internal.showEditTriggerTypeModal !== undefined ? state.internal.showEditTriggerTypeModal : internalSlice.getInitialState().showEditTriggerTypeModal);
export const getShowCreateTriggerTypeModal = state => (state.internal.showCreateTriggerTypeModal !== undefined ? state.internal.showCreateTriggerTypeModal : internalSlice.getInitialState().showCreateTriggerTypeModal);
export const getShowEditDepartmentModal = state => (state.internal.showEditDepartmentModal !== undefined ? state.internal.showEditDepartmentModal : internalSlice.getInitialState().showEditDepartmentModal);
export const getShowCreateDepartmentModal = state => (state.internal.showCreateDepartmentModal !== undefined ? state.internal.showCreateDepartmentModal : internalSlice.getInitialState().showCreateDepartmentModal);
export const getShowErrorFeedback = state => (state.internal.showErrorFeedback !== undefined ? state.internal.showErrorFeedback : internalSlice.getInitialState().showErrorFeedback);
export const getShowRegisterTagsModal = state => (state.internal.showRegisterTagsModal !== undefined ? state.internal.showRegisterTagsModal : internalSlice.getInitialState().showRegisterTagsModal);
export const getShowEditTagsModal = state => (state.internal.showEditTagsModal !== undefined ? state.internal.showEditTagsModal : internalSlice.getInitialState().showEditTagsModal);
export const getShowMeetButton = state => (state.internal.showMeetButton !== undefined ? state.internal.showMeetButton : internalSlice.getInitialState().showMeetButton);
export const getShowCallNotificationBar = state => (state.internal.showCallNotificationBar !== undefined ? state.internal.showCallNotificationBar : internalSlice.getInitialState().showCallNotificationBar);
export const getShowRequestWhatsappModal = state => (state.internal.showRequestWhatsappModal !== undefined ? state.internal.showRequestWhatsappModal : internalSlice.getInitialState().showRequestWhatsappModal);
export const getShowRequestTelefoneModal = state => (state.internal.showRequestTelefoneModal !== undefined ? state.internal.showRequestTelefoneModal : internalSlice.getInitialState().showRequestTelefoneModal);
export const getCleanMessageEditor = (state: { internal: { cleanMessageEditor: boolean } }) => (state.internal.cleanMessageEditor !== undefined ? state.internal.cleanMessageEditor : internalSlice.getInitialState().cleanMessageEditor);
export const getUpdateButtonNumberOfTickets = (state: { internal: { updateButtonNumberOfTickets: boolean } }) => (state.internal.updateButtonNumberOfTickets !== undefined ? state.internal.updateButtonNumberOfTickets : internalSlice.getInitialState().updateButtonNumberOfTickets);
export const getShowAcquirePlatformComponent = (state: { internal: { showAcquirePlatformComponent: { currentContractStatusId: string, visibility: boolean, limitDays: number, quantityAgents: number } } }) => (state.internal.showAcquirePlatformComponent !== undefined ? state.internal.showAcquirePlatformComponent : internalSlice.getInitialState().showAcquirePlatformComponent);
export const getSortByList = state => (state.internal.sortByList !== undefined ? state.internal.sortByList : internalSlice.getInitialState().sortByList);
export const getOrderByList = state => (state.internal.orderByList !== undefined ? state.internal.orderByList : internalSlice.getInitialState().orderByList);
export const getOpenSidebar = state => (state.internal.openSidebar !== undefined ? state.internal.openSidebar : internalSlice.getInitialState().openSidebar);
export const getSidebarPage = state => (state.internal.sidebarPage !== undefined ? state.internal.sidebarPage : internalSlice.getInitialState().sidebarPage);
export const getForceLogout = state => (state.internal.forceLogout !== undefined ? state.internal.forceLogout : internalSlice.getInitialState().forceLogout);
export const getForceLogoutExpired = state => (state.internal.forceLogoutExpired !== undefined ? state.internal.forceLogoutExpired : internalSlice.getInitialState().forceLogoutExpired);
export const getMute = state => (state.internal.mute !== undefined ? state.internal.mute : internalSlice.getInitialState().mute);
export const getShowUpdatedBot = state => (state.internal.updatedBot !== undefined ? state.internal.updatedBot : internalSlice.getInitialState().updatedBot);
export const getOpenDashboard = state => (state.internal.openDashboard !== undefined ? state.internal.openDashboard : internalSlice.getInitialState().openDashboard);
export const getPromptUpdatedNlp = state => (state.internal.promptUpdatedNlp !== undefined ? state.internal.promptUpdatedNlp : internalSlice.getInitialState().promptUpdatedNlp);
export const getUserWebchatId = state => (state.internal.userWebchatId !== undefined ? state.internal.userWebchatId : internalSlice.getInitialState().userWebchatId);
export const getShowModalConsumersSimilarData = state => (state.internal.showModalConsumersSimilarData !== undefined ? state.internal.showModalConsumersSimilarData : internalSlice.getInitialState().showModalConsumersSimilarData);
export const getShowModalUnifyConsumers = state => (state.internal.showModalUnifyConsumers !== undefined ? state.internal.showModalUnifyConsumers : internalSlice.getInitialState().showModalUnifyConsumers);
export const getBlockLoadTicketBackup = state => (state.internal.blockLoadTicketBackup !== undefined ? state.internal.blockLoadTicketBackup : internalSlice.getInitialState().blockLoadTicketBackup);
export const getOpenHistory = state => (state.internal.openHistory !== undefined ? state.internal.openHistory : internalSlice.getInitialState().openHistory);
export const getShowSendTicketCopyModal = state => (state.internal.showSendTicketCopyModal !== undefined ? state.internal.showSendTicketCopyModal : internalSlice.getInitialState().showSendTicketCopyModal);
export const getShowCreateIntegrationModal = state => (state.internal.showCreateIntegrationModal !== undefined ? state.internal.showCreateIntegrationModal : internalSlice.getInitialState().showCreateIntegrationModal);
export const getShowEditIntegrationModal = state => (state.internal.showEditIntegrationModal !== undefined ? state.internal.showEditIntegrationModal : internalSlice.getInitialState().showEditIntegrationModal);
export const getShowCreateParamModal = state => (state.internal.showCreateParamModal !== undefined ? state.internal.showCreateParamModal : internalSlice.getInitialState().showCreateParamModal);
export const getInAttendance = state => (state.internal.inAttendance !== undefined ? state.internal.inAttendance : internalSlice.getInitialState().inAttendance);
export const getStartAttendance = state => (state.internal.startAttendance !== undefined ? state.internal.startAttendance : internalSlice.getInitialState().startAttendance);
export const getShowEditTriageModal = state => (state.internal.showEditTriageModal !== undefined ? state.internal.showEditTriageModal : internalSlice.getInitialState().showEditTriageModal);
export const getShowCreateTriageModal = state => (state.internal.showCreateTriageModal !== undefined ? state.internal.showCreateTriageModal : internalSlice.getInitialState().showCreateTriageModal);
export const getShowEditTaskModal = state => (state.internal.showEditTaskModal !== undefined ? state.internal.showEditTaskModal : internalSlice.getInitialState().showEditTaskModal);
export const getShowRegisterDomainModal = state => (state.internal.showRegisterDomainModal !== undefined ? state.internal.showRegisterDomainModal : internalSlice.getInitialState().showRegisterDomainModal);
export const getShowViewDomainModal = state => (state.internal.showViewDomainModal !== undefined ? state.internal.showViewDomainModal : internalSlice.getInitialState().showViewDomainModal);
export const getShowCreateTemplateMessage = state => (state.internal.showCreateTemplateMessage !== undefined ? state.internal.showCreateTemplateMessage : internalSlice.getInitialState().showCreateTemplateMessage);
export const getShowEditTemplateMessage = state => (state.internal.showEditTemplateMessage !== undefined ? state.internal.showEditTemplateMessage : internalSlice.getInitialState().showEditTemplateMessage);
export const getShowTemplateTagModal = state => (state.internal.showTemplateTagModal !== undefined ? state.internal.showTemplateTagModal : internalSlice.getInitialState().showTemplateTagModal);
export const getShowEditTypeAgent = state => (state.internal.showEditTypeAgent !== undefined ? state.internal.showEditTypeAgent : internalSlice.getInitialState().showEditTypeAgent);
export const getShowEditPaswordAgent = state => (state.internal.showEditPaswordAgent !== undefined ? state.internal.showEditPaswordAgent : internalSlice.getInitialState().showEditPaswordAgent);
export const getShowRecoverPasswordAgente = state => (state.internal.showRecoverPasswordAgente !== undefined ? state.internal.showRecoverPasswordAgente : internalSlice.getInitialState().showRecoverPasswordAgente);
export const getShowTicketNotification = state => (state.internal.showTicketNotification !== undefined ? state.internal.showTicketNotification : internalSlice.getInitialState().showTicketNotification);
export const getSoundNotification = state => (state.internal.soundNotification !== undefined ? state.internal.soundNotification : internalSlice.getInitialState().soundNotification);
export const getVisualNotification = state => (state.internal.visualNotification !== undefined ? state.internal.visualNotification : internalSlice.getInitialState().visualNotification);
export const getShowManageTicketsModal = state => (state?.internal?.showManageTicketsModal !== undefined ? state?.internal?.showManageTicketsModal : internalSlice?.getInitialState()?.showManageTicketsModal);
export const getShowExportTicketModal = state => (state.internal.showExportTicketModal !== undefined ? state.internal.showExportTicketModal : internalSlice.getInitialState().showExportTicketModal);
export const getShowRegisterSummary = state => (state.internal.showRegisterSummary !== undefined ? state.internal.showRegisterSummary : internalSlice.getInitialState().showRegisterSummary);
export const getShowEditFacebookPage = state => (state.internal.showEditFacebookPage !== undefined ? state.internal.showEditFacebookPage : internalSlice.getInitialState().showEditFacebookPage);
export const getShowLinkModal = state => (state.internal.showLinkModal !== undefined ? state.internal.showLinkModal : internalSlice.getInitialState().showLinkModal);
export const getShowExportAllTicketModal = state => (state.internal.showExportAllTicketModal !== undefined ? state.internal.showExportAllTicketModal : internalSlice.getInitialState().showExportAllTicketModal);
export const getShowMessageSend = state => (state.internal.showMessageSend !== undefined ? state.internal.showMessageSend : internalSlice.getInitialState().showMessageSend);
export const getNextTicketId = state => (state.internal.nextTicketId !== undefined ? state.internal.nextTicketId : internalSlice.getInitialState().nextTicketId);
export const getQuickMessageId = state => (state.internal.quickMessageId !== undefined ? state.internal.quickMessageId : internalSlice.getInitialState().quickMessageId);
export const getReopenTicket = state => (state.internal.reopenTicket !== undefined ? state.internal.reopenTicket : internalSlice.getInitialState().reopenTicket);
export const getHistorySortBy = state => (state.internal.historySortBy !== undefined ? state.internal.historySortBy : internalSlice.getInitialState().historySortBy);

export default internalSlice.reducer;
